import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycService } from 'src/app/shared/services/kyc/kyc.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUUVID } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  kycForm: FormGroup
  displayForm: boolean = false
  customerUuid: string
  constructor(
    private fb:FormBuilder,
    private toaster:ToastrService,
    private kycService: KycService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService
  ){

  }

  ngOnInit(): void {
    this.customerUuid = this.sessionService.get(CUSTOMERUUVID)
    this.kycForm = this.fb.group({
      name:['',[Validators.required]],
      dob:['',[Validators.required]],
      emiratesId:['',[Validators.required,Validators.minLength(8)]]
    })
  }
  get formControl(){
    return this.kycForm.controls
  }

  async checkKycService(body){
    try {
      this.spinner.show()
      const response = await this.kycService.checkKyc(body)

      if(response.statusCode==200 && response.length >= 1){
        this.toaster.success("Successfully submitted kyc details")
        this.router.navigate([`/${this.customerUuid}/email-update`])
      }
      else{
        console.log("ERROR Page")
        this.router.navigate([`/${this.customerUuid}/app-expiry`])
      }
    } catch (error) {
      // this.toaster.error(error.message)
    }finally{
      this.spinner.hide()
    }
  }

  async checkKyc(){
  if(this.kycForm.invalid){
    this.toaster.error("Enter valid KYC details")
  }else{
    const requestBody = {...this.kycForm.value,customerUuid:this.customerUuid}
    await this.checkKycService(requestBody)
  }
}

backToLogin(){
  this.router.navigate([`/login`])
}
}
