import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  listOfPoliciesObject:BehaviorSubject<any> = new BehaviorSubject<any>([])
  drawerObject : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  drawerStatus$  = this.drawerObject.asObservable()
  listOfPolicies$ = this.listOfPoliciesObject.asObservable()

  constructor() { }
  changeDrawerStatus(list?:any): void{
    const currentValue = this.drawerObject.getValue()
    this.drawerObject.next(!currentValue)
    if(list){
      this.listOfPoliciesObject.next(list)
    }
  }

}
