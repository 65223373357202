import { Injectable } from '@angular/core';
import { QuoteSummaryService } from '../quote-summary/quote-summary.service';
import { Router } from '@angular/router';
import { flowBasedRouting } from '../../mock-data';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private quoteSumaryService: QuoteSummaryService, private router: Router) { }

  async getCustomerLastWorkflow(customerUuvid) {
    try {
      let flowdata = flowBasedRouting;
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(flowdata);
      this.router.navigateByUrl(`${customerUuvid}/${getFLows[0].route}`);
    } catch (exception) {

    }
  }

  async getXADataValidation(customerUuvid) {
    try {
      let flowdata = flowBasedRouting;
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(flowdata);
      this.router.navigateByUrl(`${customerUuvid}/${getFLows[0].route}`);
    } catch (exception) {

    }
  }
}
