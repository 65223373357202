import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownStatusService {

  constructor() { }

  private dropdownStatusSubject = new Subject<string>()

  dropdownStatus$ = this.dropdownStatusSubject.asObservable() 

  notifyDropdownStatus(id:string){
    this.dropdownStatusSubject.next(id)
  }
}
