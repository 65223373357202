import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycService } from 'src/app/shared/services/kyc/kyc.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUUVID } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup
  customerUuid:string
  version:string
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private kycService: KycService,
    private router: Router,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.customerUuid = this.sessionService.get(CUSTOMERUUVID)
    this.version = this.sessionService.get('version')
    this.forgotPasswordForm = this.fb.group({
      customerUniqueId: ['', [Validators.required]],
    })
  }
  get formControl() {
    return this.forgotPasswordForm.controls
  }

  backToLogin(){
    this.router.navigate([`/login`])
  }

  async forgotPassword() {

    try {
      if(this.forgotPasswordForm.invalid){
        this.toaster.error("Enter valid Customer Id")
        return
      }
      this.spinner.show()
      const response = await this.kycService.forgotPassword({
        // customerUuid:this.customerUuid,
        // version:this.version,
        // emailId: this.forgotPasswordForm.value['email']
        customerUserId:this.forgotPasswordForm.value['customerUniqueId']
      })
      console.log(response)
      if(response){   
        this.toaster.success("Check Your Email For Credentials")
        this.router.navigate([`/login`])
      }
    } catch (error) {
      console.log(error)
      // this.toaster.error(error.message)
    }
    finally{
      this.spinner.hide()
    }
  }
}
