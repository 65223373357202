import { Component, Input, OnInit } from '@angular/core';
interface plan  {
  productId:string
  productName:string,
  payablePremium:number,
  mandatoryBenfits: string[]
}

@Component({
  selector: 'app-web-plan',
  templateUrl: './web-plan.component.html',
  styleUrls: ['./web-plan.component.scss']
})
export class WebPlanComponent implements OnInit {
  isSelected: boolean = true
  @Input() selectedPlanId:number
  @Input() plan

  ngOnInit(): void {
  }
}
