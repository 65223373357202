import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import axios, { AxiosInstance } from "axios";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { BaseInterceptor } from '../interceptors/base.interceptor';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  revisedPremium:any;
  secretKeyConfig={ };

  private axios: AxiosInstance;
  constructor(private router:Router, private toaster: ToastrService,private baseInterceptor: BaseInterceptor) {
    this.axios = axios.create({
      baseURL: environment.apiUrl,
    });
    baseInterceptor.interceptor(this.axios);
  }



  async getApiCall(url: string, params?: Record<string, string | number>): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl + url;
      let response = await this.axios.get(urlStr, {
        params
      });
      return response["data"];
    } catch (exception:any) {
            throw exception;
    }
  }

  async postApiCall(url: string, params: any): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl +  url;
      let response = await this.axios.post(urlStr, params); 
      return response['data'];
    } catch (exception: any) {
      throw exception;
    }
  }

  async putApiCall(url: string, params: any): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl + url;
      let response = await this.axios.put(urlStr, params);
      return response['data'];
    } catch (exception: any) {
      throw exception;
    }
  }

  async deleteApiCall(url: string): Promise<any> {
    try {
      // let secretKeyConfig = this.getHeaders();
      let urlStr = environment.apiUrl + url;
      let response = await this.axios.delete(urlStr);
      return response['data'];
    } catch (exception: any) {
      throw exception;
    }
  }

}
