<div class="flex flex-col justify-between items-center h-screen">
    <div class="w-full">
        <!-- <app-header [hideProfileIcon]="true" [hideShareIcon]="true"></app-header> -->
        <app-header-v2  [pageName]="'forgot-password'"></app-header-v2>
    </div>
    <div class="flex-1 flex-col flex justify-center items-center w-full max-w-96">
        <div class="flex justify-between w-full">
            <button type="button" (click)="backToLogin()"> 
                <img  src="/assets/svg/back.svg" alt="back">
            </button>
            <h1 class="text-3xl font-semibold my-8 ">Forgot Password</h1>
            <div></div>
        </div>
        <div class="w-full h-1 bg-[#FF6B00] max-w-96"></div>
        <form [formGroup]="forgotPasswordForm" class="border  w-full p-4 bg-gray-100 max-w-96" (ngSubmit)="forgotPassword()">
            <div class="flex flex-col gap-4 ">
                <p class="text-center text-sm text-black">
                    Please provide your Customer Id to receive your LogIn credentials.</p>
                <!-- <div class="flex flex-col gap-2" >
                    <label class="font-semibold" for="email">Email</label>
                    <input class="h-8" name="email"  type="email" formControlName="email">
                    <div *ngIf="formControl['email'].touched && formControl['email'].invalid">
                        <div *ngIf="formControl['email'].errors['required']" class="text-[#FF6B00] text-sm">Email is required</div>
                        <div *ngIf="formControl['email'].errors['email']" class="text-[#FF6B00] text-sm">Enter Valid Email</div>
                    </div>
                </div> -->
                <div class="flex flex-col gap-2" >
                    <label class="font-semibold" for="customerUniqueId">Customer Id</label>
                    <input class="h-8" name="customerUniqueId"    type="text" formControlName="customerUniqueId">
                    <div *ngIf="formControl['customerUniqueId'].touched && formControl['customerUniqueId'].invalid">
                        <div *ngIf="formControl['customerUniqueId'].errors['required']" class="text-[#FF6B00] text-sm">Custome Id is required</div>
                    </div>
                </div>
            </div>
            <div class="text-center my-4">
                <button class="bg-[#FF6B00] text-center rounded-full  text-white p-2 w-full  font-semibold text-xl"  type="submit">Submit</button>
            </div>
        </form>
    </div>
    <div class="w-full">
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>
