import { Injectable } from '@angular/core';
import {ApiService} from 'src/app/core/api/api-service/api.service';
import {API_ENDPOINTS} from '../../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private  apiService:ApiService) { }

  async createPayment(params) {
    try {
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS.payment}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async paymentStatus(params)
  {
    try {
      let apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS.payment}/${API_ENDPOINTS.paymentStatus}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async verifyPaymentStatus(params)
  {
    try {
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS.payment}/${API_ENDPOINTS.verifyPaymentStatus}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }
}
