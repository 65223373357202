<div
  class=" bg-transparent min-h-dvh flex flex-col justify-end md:items-center absolute duration-1000 transition-opacity inset-0 z-50"
  [ngClass]="drawerStatus ? 'flex' : 'hidden'"
  (click)="changeDrawerStatus()"
>
  <div
    id="drawer-bottom-example"
    class="  rounded-t-[1.25rem] fixed bottom-0 left-0 right-0 z-40 w-full min-h-96 md:max-w-[896px]  md:left-auto md:right-auto py-6 px-8 overflow-y-auto transition-all bg-[#FFF0E5]"
    [ngClass]="drawerStatus ? 'open-drawer' : ''"
    (click)="$event.stopImmediatePropagation()"
  >
    <!-- <div class="flex justify-center items-center mb-10"> -->
      <div class="flex justify-center items-center m-1">
      <img src="assets/svg/drawer-dash.svg" alt="">
    </div>
    <div class="flex items-center justify-between mb-4">
      <h5
        class="inline-flex items-center text-base  font-bold  md:text-[1.375rem]"
      >
       {{planName}}
      </h5>
    </div>
    <div class="">
        <ul >
            <li class="flex items-start gap-4 text-sm font-normal md:text-lg " *ngFor="let list of listOfPolicies">
                <img src="/assets/svg/right.svg" alt="">
                {{list}}
                <!-- {{listOfPolicies | json}} -->
             </li>
        </ul>
  </div>
  </div>
</div>
