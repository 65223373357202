import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownStatusService } from '../../services/dorpdown/dropdown-status.service';
import { MasterServiceService } from '../../services/masters/master-service.service';
import * as moment from 'moment';
import { QuoteSummaryService } from '../../services/quote-summary/quote-summary.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-dynamic-select',
  templateUrl: './dynamic-select.component.html',
  styleUrls: ['./dynamic-select.component.scss']
})
export class DynamicSelectComponent implements OnInit {
  @Output() emitChasssiNumberStatus : EventEmitter<any> = new EventEmitter();
  @Output() enableChassisEdit = false;
  @Input() title: string;
  @Input() selected: any;
  @Input() options: any;
  @Input() type: string;
  @Input() id: string;
  @Input() masterKey: string;
  @Input() index: number;
  @Input() dbField: string;
  @Input() policyExpiryDate: string;
  @Input() vehicleSpecification: any;
  @Input() showMakeModel: any;
  oldChassisNumber:any;

  @Output() errorStatus: EventEmitter<{ errorStatus: boolean }> = new EventEmitter<{ errorStatus: boolean }>()
  @Output() selectedValuePair: EventEmitter<{ label: string, value: string, dbField: string, code: number, masterKey: string, index: number }> = new EventEmitter<{ label: string, value: string, dbField: string, code: number, masterKey: string, index: number }>()
  showDropdown: boolean = false
  searchedItmes: Array<any>
  searchInputValue: string
  changeParaToInput: boolean = false
  emailValue: string
  inputDateValue: Date
  dateLimitValue: Date | string
  inputValue: string
  checkedValue: string
  chassisNumberLabel:string
  chassisNumberValue:string

  errorObject = {
    emailEmptyError: false,
    emailWrongFormatError: false,
    ncbYearsEmptyError: false,
    ncbYearsWronFormatError: false,
    mobileNumberEmptyError: false,
    mobileNumberWrongFormatError: false,
    policyStartDateError: false,
    chasisNumberLengthError: false,
    chasisNumberInvalidError: false,
    firstNameLengthError: false,
    lastNameLengthError: false,
    yearOfManufactureError: false,
    plateNumberPatternError: false,
    dateOfBirthError:false
  }

  constructor(
    private dropdownStatusService: DropdownStatusService,
    private quoteSummaryService: QuoteSummaryService,
    private toasterService: ToastrService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.searchedItmes = this.options
    this.setValuesAsPerType(this.type)
    // console.log(this.dbField,this.type)
    this.dropdownStatusService.dropdownStatus$.subscribe((id) => {
      this.showDropdown = (this.id === id)
    })
  }



  setValuesAsPerType(type: string) {

    if (type === 'date') {
      // if(this.dbField == 'issueDate' || 'eidExpiryDate'){
      // //  this.selected=  this.formatDate(this.selected)
      // }
      this.inputDateValue = this.selected.slice(0, 10)
      if (this.dbField == 'effectiveDate') {
        this.dateLimitValue = this.selected.slice(0, 10)
      }
    }
    else if (type === 'email') {
      this.emailValue = this.selected
    } else if (type == 'static' && this.dbField=="dob"){
      this.selected = this.selected.split("-").reverse().join("-")
    } else if(this.dbField == 'dob'){
      this.inputDateValue = this.selected.slice(0,10)
      this.dateLimitValue =  this.ageGreaterThanEighteenYears()
     }
    else {
      this.inputValue = this.selected
    }
  }

  formatDate(date: string) {
    const testDate = new Date(date)
    const year = testDate.getFullYear()
    const month = String(testDate.getMonth() + 1).padStart(2, '0')
    const day = String(testDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  changeDropdownStatus(id: string) {
    this.showDropdown ? this.dropdownStatusService.notifyDropdownStatus('') : this.dropdownStatusService.notifyDropdownStatus(id)
  }

  dropDownItemSelected(label: string, value: any, code?: number) {
    this.selected = value
    this.changeDropdownStatus('')
    this.searchInputValue = ''
    this.searchedItmes = this.options
    this.sendSelectedItems(label, value, code)
  }

  showSearchItem() {
    const newItemsArray = []
    this.options.filter((item) => {
      if (item.description.toLowerCase().includes(this.searchInputValue.toLowerCase())) {
        newItemsArray.push(item)
      }
    }
    )
    this.searchedItmes = [...newItemsArray]
  }

  sendValue(event?: any, label?: string, value?: any, code?: number) {
    this.changeParaToInput = !this.changeParaToInput
    if (label === 'Policy Start Date') {
      this.checkPolicyStartDateError(event)
    }
    if (label === 'Email') {
      this.checkEmailError(event)
    }
    if (label === 'Mobile Number') {
      this.checkMobileNumberError(event)
    }
    if (label === 'Chassis Number') {
      this.chassisNumberLabel = label
      this.chassisNumberValue = value
      this.checkChassisNumberError(event)
    }
    if (label === 'First Name') {
      this.checkFirstNameError(event)
    }
    if (label === 'Last Name') {
      this.checkLastNameError(event)
    }
    if (label === 'Year Of Manufacture') {
      this.checkYearOfManufactureError(event)
    }
    if (label === 'Plate Number') {
      this.checkPlateNumberError(event)
    }
    if(label == 'Date of Birth'){
      this.checkDateOfBirthError(event)
    }
    if (label && label !== 'Chassis Number') {
      this.sendSelectedItems(label, value, code)
      this.sendErrorStatus()
    }
  }

  sendSelectedItems(label: string, value: string, code?: number) {
    this.selectedValuePair.emit({ label, value, dbField: this.dbField, code, masterKey: this.masterKey, index: this.index })
  }

  disableDate() {
    return false
  }

  ageGreaterThanEighteenYears(){
    const today = new Date()
    const date18YearsAgo = new Date()
    date18YearsAgo.setFullYear(today.getFullYear() - 18)
    return date18YearsAgo.toISOString().slice(0,10)
  }

  checkDateOfBirthError(event){
    if(event.target.value > event.target.max){
      this.errorObject.dateOfBirthError = true
    }else{
      this.errorObject.dateOfBirthError = false
    }
  }

  checkPolicyStartDateError(event) {
    if (event.target.value < event.target.min) {
      this.errorObject.policyStartDateError = true
    } else {
      this.errorObject.policyStartDateError = false
    }
  }

  checkEmailError(event) {
    const regx = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const pattern = new RegExp(regx)

    if (event.target.value.trim() === '') {
      this.errorObject.emailEmptyError = true
      this.errorObject.emailWrongFormatError = false;
    } else if (!pattern.test(event.target.value)) {
      this.errorObject.emailWrongFormatError = true;
      this.errorObject.emailEmptyError = false
    }
    else {
      this.errorObject.emailWrongFormatError = false;
      this.errorObject.emailEmptyError = false;
    }
  }



  checkMobileNumberError(event) {
    const key = event.key
    const charCodeValue = event.key.charCodeAt()
    const allowedKeys = ['Backspace', 'Tab', 'Enter','ArrowRight','ArrowLeft']
    if ((event.which != 8 && event.which != 0 && event.which < 48) || event.which > 57) {
      event.preventDefault();
    }

    const regex = new RegExp(/^\D*(\d\D*){10}$/);
    const valid = regex.test(event.target.value.toString());
    if (event.target.value.trim() === '') {
      this.errorObject.mobileNumberEmptyError = true
      this.errorObject.mobileNumberWrongFormatError = false
    }
    //  else if (!valid) {
    //   console.log("--enter else if")
    //   this.errorObject.mobileNumberWrongFormatError = true
    //   this.errorObject.mobileNumberEmptyError = false
    // }
    else {
      this.errorObject.mobileNumberEmptyError = false
      this.errorObject.mobileNumberWrongFormatError = false
    }
  }

  checkChassisNumberError(event) {
    if (event.target.value.trim().length == 17) {
      this.errorObject.chasisNumberLengthError = false;
    } else {
      this.errorObject.chasisNumberLengthError = true;
      this.errorObject.chasisNumberInvalidError = false;
    }
  }

  checkFirstNameError(event) {
    if (event.target.value.trim().length < 1) {
      this.errorObject.firstNameLengthError = true
    } else {
      this.errorObject.firstNameLengthError = false
    }
  }

  checkLastNameError(event) {
    if (event.target.value.trim().length < 1) {
      this.errorObject.lastNameLengthError = true
    } else {
      this.errorObject.lastNameLengthError = false
    }
  }

  checkYearOfManufactureError(event) {
    const currentYear = new Date().getFullYear()
    if (event.target.value > currentYear) {
      this.errorObject.yearOfManufactureError = true
    } else {
      this.errorObject.yearOfManufactureError = false
    }

  }

  checkPlateNumberError(event) {
    const regx = /^[A-Za-z][A-Za-z0-9]{5}$/
    const pattern = new RegExp(regx)
    console.log(pattern.test(event.target['value']))
    if (!pattern.test(event.target.value.trim())) {
      this.errorObject.plateNumberPatternError = true
    } else {
      this.errorObject.plateNumberPatternError = false
    }
  }

  sendErrorStatus() {
    const values = Object.values(this.errorObject)
    this.errorStatus.emit({ errorStatus: values.includes(true) })
  }

  editChassisNumber() {
    try {
      this.oldChassisNumber =  this.inputValue;
      this.enableChassisEdit = true;
      this.emitChasssiNumberStatus.emit(true);
      this.showMakeModel = false;
    } catch (exception) {

    }

  }
  enableCancel() {
    this.enableChassisEdit =false;
    this.emitChasssiNumberStatus.emit(false);
    this.showMakeModel = true;
    this.inputValue = this.oldChassisNumber;
    this.selected = this.oldChassisNumber;

    this.errorObject.chasisNumberLengthError = false;
    this.errorObject.chasisNumberInvalidError = false;
    this.sendErrorStatus()
  }
  async enableTick() {

    try {
      this.spinner.show()
      if (this.inputValue.trim().length == 17) {
        this.errorObject.chasisNumberLengthError = false;
      } else {
        this.errorObject.chasisNumberLengthError = true;
      }
      if (!this.errorObject.chasisNumberLengthError) {
        let response = await this.quoteSummaryService.getChassisNumber(this.inputValue);
        if (!response?.response.length || response?.response[0].hasOwnProperty('Error')) {
          this.errorObject.chasisNumberInvalidError = true
          this.errorObject.chasisNumberLengthError = false;
          return;
        }
        this.errorObject.chasisNumberInvalidError = false
        this.enableChassisEdit = false;
        this.emitChasssiNumberStatus.emit(false);
        this.showMakeModel = true;
        this.vehicleSpecification.make = response.response[0].Make;
        this.vehicleSpecification.model = response.response[0].Model;
        this.vehicleSpecification.variant = response.response[0].ModelSpecification;
        this.vehicleSpecification.year = response.response[0].YearOfManf;
        this.selected =  this.inputValue;
        this.sendSelectedItems(this.chassisNumberLabel,this.chassisNumberValue)
      }
    } catch (exception) {

    }
    finally{
      this.spinner.hide()
      this.sendErrorStatus()
    }
  }

}
