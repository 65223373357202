<div class="hidden md:grid grid-cols-card-md  lg:grid-cols-card-lg xl:grid-cols-card-xl  gap-y-6 gap-x-16">
    <div class="flex flex-col gap-[1.125rem]">
        <p class="font-semibold text-lg">Plate Number</p>
        <div class="font-normal text-lg py-4 pl-5 border border-[rgba(112,112,112,0.3)] rounded-md">{{quoteDetailPageDetails?.['quoteSummaryDetails']?.[0]?.value}}</div>
    </div>
    <div class="flex flex-col gap-[1.125rem]">
        <p class="font-semibold text-lg">Vehicle</p>
        <div class="font-normal text-lg py-4 pl-5 border border-[rgba(112,112,112,0.3)] rounded-md">{{quoteDetailPageDetails?.['quoteSummaryDetails']?.[1]?.value }} {{ quoteDetailPageDetails?.['quoteSummaryDetails'][2]?.value }}  {{quoteDetailPageDetails?.['quoteSummaryDetails'][3]?.value}}</div>
    </div>
    <div class="flex flex-col gap-[1.125rem]">
        <p class="font-semibold text-lg">Insured Value</p>
        <div class="font-normal text-lg py-4 pl-5 border border-[rgba(112,112,112,0.3)] rounded-md">{{quoteDetailPageDetails?.['quoteSummaryDetails']?.[4]?.value?'AED':''}} {{quoteDetailPageDetails?.['quoteSummaryDetails']?.[4]?.value | number}}</div>
    </div>
    <div class="flex flex-col gap-[1.125rem]">
        <p class="font-semibold text-lg">Policy Start Date</p>
        <div class="font-normal text-lg py-4 pl-5 border border-[rgba(112,112,112,0.3)] rounded-md">{{quoteDetailPageDetails?.['quoteSummaryDetails']?.[5]?.value.split("-").reverse().join("-")}}</div>
    </div>
    <div class="flex flex-col gap-[1.125rem]">
        <p class="font-semibold text-lg">Policy End Date</p>
        <div class="font-normal text-lg py-4 pl-5 border border-[rgba(112,112,112,0.3)] rounded-md">{{quoteDetailPageDetails?.['quoteSummaryDetails']?.[6]?.value.split("-").reverse().join("-")}}</div>
    </div>

</div>
