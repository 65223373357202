
export interface CityMasters {
  id: number,
  cityCode: string,
  cityDescription: string,
}
export interface NationalityMasters {
  id: number,
  nationalityCode: string,
  nationalityDescription: string,
}
export interface ColorMasters {
  id: number,
  colorCode: string,
  colorDescription: string,
}
export interface MakeMasters {

}

export interface ModelMasters {

}

export interface VarientMasters{

}

export const TOKEN = 'token';
export const QUOTEENQUIRYID = 'quoteEnquiryId';
export const PREVIOUSWORKFLOW = 'previousWorkflow';
export const VERSION = 'version';
export const CURRENTWORKFLOW = 'currentWorkFlow';
