import { Component } from '@angular/core';

@Component({
  selector: 'app-web-topups',
  templateUrl: './web-topups.component.html',
  styleUrls: ['./web-topups.component.scss']
})
export class WebTopupsComponent {
  topUpList = [{
    id:1,
    isSelected: true,
    premium:100,
    coverDesc:"Accidental Cover"
  }]

  totalPrice = 100.0

  clickToSelectTopUpList(id){
  }

  createQuote(){
  }

}
