<div *ngIf="type === 'select'">
  <label
    for=""
    class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
    >{{ title }}</label
  >
  <div
    *ngIf="type === 'select'"
    class="md:rounded-md w-full p-[0.9dvh] flex justify-between bg-[#F6F6F6F6] rounded-3xl items-center md:bg-white md:h-[50px] md:border-solid border border-neutral-300 md:w-[315px]"
  >
    <label for="" class="md:hidden text-xs md:text-sm">{{ title }}</label>
    <div class="flex items-center justify-cente md:w-full">
      <div class="relative group md:w-full">
        <button
          id="dropdown-button"
          (click)="changeDropdownStatus(id)"
          class="md:justify-between inline-flex justify-center items-center w-full text-xs font-semibold xs:font-medium rounded-md border-none focus:outline-none border px-[.9dvh]"
        >
          <span class="mr-2 md:text-sm md:font-normal">{{ selected }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 ml-2 -mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div
          id="dropdown-menu"
          [ngClass]="showDropdown ? '' : 'hidden'"
          class="absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-40 max-h-60 min-w-40 overflow-auto"
        >
          <input
            id="search-input"
            class="md:font-normal md:text-sm block w-full px-[1.7dvh] py-[0.9dvh] border rounded-md border-[#f6f6f6f6] focus:outline-none"
            type="text"
            placeholder="Search..."
            autocomplete="off"
            [(ngModel)]="searchInputValue"
            (keyup)="showSearchItem()"
          />
          <button
            class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md text-xs"
            *ngFor="let option of searchedItmes"
            (click)="
              dropDownItemSelected(title, option.description, option.code)
            "
          >
            {{ option.description }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type === 'gender'"
  class="w-full p-[0.9dvh] flex justify-between bg-[#F6F6F6F6] rounded-3xl items-center"
>
  <label for="" class="text-xs">{{ title }}</label>
  <div class="flex items-center justify-center">
    <div class="relative group">
      <button
        id="dropdown-button"
        (click)="changeDropdownStatus(id)"
        class="inline-flex justify-center items-center w-full text-xs font-semibold rounded-md border-none focus:outline-none border px-[.9dvh]"
      >
        <span class="mr-2">{{ selected }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 ml-2 -mr-1"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div
        id="dropdown-menu"
        [ngClass]="showDropdown ? '' : 'hidden'"
        class="absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-40 max-h-60 min-w-40 overflow-auto"
      >
        <!-- <input id="search-input" class="block w-full px-[1.7dvh] py-[0.9dvh]  border rounded-md  border-[#f6f6f6f6] focus:outline-none" type="text" placeholder="Search..." autocomplete="off" [(ngModel)]="searchInputValue"  (keyup)="showSearchItem()"> -->
        <button
          class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md text-xs"
          (click)="dropDownItemSelected(title, 'M')"
        >
          M
        </button>
        <button
          class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md text-xs"
          (click)="dropDownItemSelected(title, 'F')"
        >
          F
        </button>
        <button
          class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md text-xs"
          (click)="dropDownItemSelected(title, 'O')"
        >
          O
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="type === 'static'">
  <label
    for=""
    class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
    >{{ title }}</label
  >
  <div
    *ngIf="type === 'static'"
    class="w-full py-[0.9dvh] pl-[0.9dvh] pr-[0.9dvh] xs:pr-[1.1dvh] flex justify-between items-center bg-[#f9f9f9] md:rounded-md rounded-3xl md:w-[315px] md:bg-[#f9f9f9] md:h-[50px] md:border-solid border border-neutral-300"
  >
    <label for="" class="md:hidden text-xs md:text-sm">{{ title }}</label>
    <p
      class="md:font-normal font-semibold text-xs xs:font-medium xs:py-[2px] md:text-sm"
    >
      {{ selected }}
    </p>
    <img
      src="assets/icons/edit.svg"
      *ngIf="showMakeModel && !enableChassisEdit"
      class="edit py-[2] pr-1"
      (click)="editChassisNumber()"
    />
  </div>
</div>
<div *ngIf="type === 'email'" class="w-full">
  <div >
    <label
      for=""
      class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      *ngIf="type === 'email'"
      class="w-full flex justify-between items-center bg-[#f9f9f9] rounded-3xl md:bg-white md:rounded-md md:w-[315px] md:h-[50px] md:border-solid border border-neutral-300 "
    >
      <label for="" class="md:hidden text-xs w-1/2 m-[0.9dvh] md:text-sm">{{
        title
      }}</label>
      <!-- <p *ngIf="!changeParaToInput" class="font-semibold text-xs" (click)="sendValue()">{{emailValue}}</p> -->
      <input
        [(ngModel)]="emailValue"
        type="email"
        class="md:w-full md:text-left text-xs xs:font-medium text-right w-1/2 rounded-r-full border-2 border-[#f6f6f6f6] p-2 md:text-sm md:border-none outline-[#FF6B00]"
        [value]="selected"
        (blur)="sendValue($event, title, emailValue)"
      />
    </div>
    <p
      *ngIf="errorObject.emailEmptyError"
      class="text-xs text-red-500 text-center"
    >
      Email shouldn't be empty
    </p>
    <p
      *ngIf="errorObject.emailWrongFormatError"
      class="text-xs text-red-500 text-center"
    >
      Enter valid email
    </p>
  </div>
</div>

<div class="w-full">
  <div *ngIf="type === 'text'" class="w-full flex flex-col">
    <label
      for=""
      class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      class="w-full flex items-center justify-between bg-[#F6F6F6F6] rounded-3xl md:rounded-md md:w-[315px] md:bg-white md:h-[50px] md:border-solid border border-neutral-300"
    >
      <label for="" class="md:hidden text-xs w-1/2 m-[0.9dvh] md:text-sm">{{
        title
      }}</label>
      <!-- <p *ngIf="!changeParaToInput" class="font-semibold text-xs" (click)="sendValue()">{{inputValue}}</p> -->
      <input
        [(ngModel)]="inputValue"
        type="text"
        class="md:w-full md:text-left md:font-normal md:border-none md:text-sm md:bg-white font-semibold text-xs xs:font-medium text-right w-1/2 rounded-r-full border-2 border-[#f6f6f6f6] p-2 outline-[#FF6B00]"
        [value]="selected"
        (change)="sendValue($event, title, inputValue)"
      />
    </div>
    <p
      *ngIf="errorObject.firstNameLengthError"
      class="text-xs text-red-500 text-center"
    >
      Firstname shouldn't be empty
    </p>
    <p
      *ngIf="errorObject.lastNameLengthError"
      class="text-xs text-red-500 text-center"
    >
      Lastname shouldn't be empty
    </p>
    <p
      *ngIf="errorObject.plateNumberPatternError"
      class="text-xs text-red-500 text-center"
    >
      Enter valid plate number
    </p>
  </div>
</div>
<div *ngIf="type === 'edit-text'">
  <div class="w-full">
    <label
      for=""
      class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      *ngIf="showMakeModel"
      class="w-full p-[0.9dvh] flex justify-between items-center bg-[#F6F6F6F6] rounded-3xl md:rounded-md md:w-[315px] md:h-[50px] md:border-solid border border-neutral-300"
    >
      <label for="" class="md:hidden text-xs md:text-sm">{{ title }}</label>
      <p
        class="md:font-normal font-semibold text-xs xs:font-medium md:text-sm"
      >
        {{ selected }}
      </p>
      <img
        src="assets/icons/edit.svg"
        *ngIf="showMakeModel && !enableChassisEdit"
        class="edit"
        (click)="editChassisNumber()"
      />
      <!-- <div class="flex justify-between items-center">
    </div> -->
    </div>
    <ng-container *ngIf="!showMakeModel">
      <div
        class="w-full flex justify-between items-center bg-[#F6F6F6F6] rounded-3xl md:rounded-md md:w-[315px] md:bg-white md:h-[50px] md:border-solid border border-neutral-300"
      >
        <label for="" class="text-xs m-[0.9dvh] md:hidden">{{ title }}</label>
        <!-- <p *ngIf="!changeParaToInput" class="font-semibold text-xs" (click)="sendValue()">{{inputValue}}</p> -->
        <input
          [(ngModel)]="inputValue"
          type="text"
          class="md:text-left  md:w-full md:font-normal md:bg-white font-semibold text-xs xs:font-medium text-right rounded-r-full border-2 border-[#f6f6f6f6] p-2 outline-[#FF6B00]"
          [value]="selected"
          (change)="sendValue($event, title, inputValue)"
        />
        <img
          src="assets/svg/tick-red.png"
          *ngIf="!showMakeModel && enableChassisEdit"
          class="tick-cancel m-2"
          (click)="enableTick()"
        />
        <img
          src="assets/svg/close.png"
          *ngIf="!showMakeModel && enableChassisEdit"
          class="tick-cancel m-2"
          (click)="enableCancel()"
        />
      </div>
    </ng-container>
  </div>
  <p
    *ngIf="errorObject.chasisNumberLengthError"
    class="text-xs text-red-500 text-center"
  >
    Chassis number must be of 17 character
  </p>
  <p
    *ngIf="errorObject.chasisNumberInvalidError"
    class="text-xs text-red-500 text-center"
  >
    Enter valid chassis number
  </p>
  <div class="text-xs mt-1 ms-2">
    {{ vehicleSpecification.make }}, {{ vehicleSpecification.model }},
    {{ vehicleSpecification.variant }}, {{ vehicleSpecification.year }}
  </div>
</div>

<div class="w-full">
  <div *ngIf="type === 'number'">
    <label
      for=""
      class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      *ngIf="type === 'number'"
      class="md:rounded-md w-full flex justify-between items-center bg-[#F6F6F6F6] rounded-3xl md:w-[315px] md:bg-white md:h-[50px] md:border-solid border border-neutral-300"
    >
      <label for="" class="md:hidden text-xs w-1/2 m-[0.9dvh] md:text-sm">{{
        title
      }}</label>
      <!-- <p *ngIf="!changeParaToInput" class="font-semibold text-xs" (click)="sendValue()">{{inputValue}}</p> -->
      <input
        [(ngModel)]="inputValue"
        type="number"
        class="md:w-full md:text-left md:font-normal md:bg-white md:text-sm font-semibold text-xs xs:font-medium text-right w-1/2 rounded-r-full border-2 border-[#f6f6f6f6] p-2 md:border-none outline-[#FF6B00]"
        [value]="selected"
        (keydown)="sendValue($event, title, inputValue)"
      />
    </div>
    <p
      *ngIf="errorObject.mobileNumberEmptyError"
      class="text-xs text-red-500 text-center"
    >
      Mobile Number shouldn't be empty
    </p>
    <p
      *ngIf="errorObject.mobileNumberWrongFormatError"
      class="text-xs text-red-500 text-center"
    >
      Enter valid mobile number
    </p>
    <p
      *ngIf="errorObject.yearOfManufactureError"
      class="text-xs text-red-500 text-center"
    >
      Eneter valid year of manufacture
    </p>
  </div>
</div>

<div class="w-full">
  <div *ngIf="type === 'date'">
    <label
      for=""
      class="font-bold hidden md:block text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      *ngIf="type === 'date'"
      class="md:rounded-md w-full flex items-center justify-between bg-[#f9f9f9] rounded-3xl md:w-[315px] md:bg-white md:h-[50px] md:border-solid border border-neutral-300"
    >
      <label for="" class="md:hidden text-xs w-1/2 m-[0.9dvh] md:text-sm">{{
        title
      }}</label>
      <input
        type="date"
        class="md:font-normal md:text-left  md:w-full md:border-none md:bg-white md:text-sm font-semibold text-xs xs:font-medium text-right w-1/2 rounded-r-full border-2 border-[#f6f6f6f6] px-1 py-2 outline-[#FF6B00]"
        [(ngModel)]="inputDateValue"
        [max]="dateLimitValue"
        (change)="sendValue($event, title, inputDateValue)"
      />
    </div>
    <p
      *ngIf="errorObject.policyStartDateError"
      class="text-xs text-red-500 text-center"
    >
      Policy Start date shouldn't be less than the previous plan expiry date
    </p>
  </div>
</div>

<div class="w-full">
  <div *ngIf="type === 'dob'">
    <label
      for=""
      class="font-bold hidden md:block text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      *ngIf="type === 'dob'"
      class="md:rounded-md w-full flex items-center justify-between bg-[#F6F6F6F6] rounded-3xl md:w-[315px] md:bg-white md:h-[50px] md:border-solid border border-neutral-300"
    >
      <label for="" class="md:hidden text-xs w-1/2 m-[0.9dvh] md:text-sm">{{
        title
      }}</label>
      <input
        type="date"
        class="md:font-normal md:text-left md:w-full md:border-none md:bg-white md:text-sm font-semibold text-xs xs:font-medium text-right w-1/2 rounded-r-full border-2 border-[#f6f6f6f6] px-1 py-2 outline-[#FF6B00]"
        [(ngModel)]="inputDateValue"
        [max]="dateLimitValue"
        (change)="sendValue($event, title, inputDateValue)"
      />
    </div>
    <p
      *ngIf="errorObject.dateOfBirthError"
      class="text-xs text-red-500 text-center"
    >
      Date of birth shouldn't be less than 18 years
    </p>
  </div>
</div>
<div>
  <div *ngIf="type === 'policyExpiryDate'">
    <label
      for=""
      class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
      >{{ title }}</label
    >
    <div
      *ngIf="type === 'policyExpiryDate'"
      class="md:rounded-md w-full py-[0.9dvh] pl-[0.9dvh] pr-[0.8dvh] xs:pr-[0.7dvh] flex justify-between items-center bg-[#f9f9f9] rounded-3xl md:w-[315px] md:bg-[#f9f9f9] md:h-[50px] md:border-solid border border-neutral-300"
    >
      <label for="" class="md:hidden text-xs md:text-sm">{{ title }}</label>
      <input
        type="date"
        class="md:text-sm md:font-normal md:text-left text-xs xs:font-medium text-right xs:py-[2px]"
        [(ngModel)]="policyExpiryDate"
        [disabled]="dbField == 'policyExpiryDate'"
        (change)="sendValue($event, title, inputDateValue)"
      />
    </div>
  </div>
  <!-- <p *ngIf="errorObject.policyStartDateError" class="text-xs text-red-500 text-center">Policy Start date shouldn't be less than the previous plan expiry date</p> -->
</div>

<div *ngIf="type === 'radio'">
  <label
    for=""
    class="hidden md:block font-bold text-xs w-1/2 m-[0.9dvh] md:text-sm"
    >Claims</label
  >
  <div
    *ngIf="type === 'radio'"
    class="md:rounded-md w-full p-[0.9dvh] flex justify-between bg-[#f9f9f9] rounded-3xl md:w-[315px] md:bg-white md:h-[50px] border border-neutral-300 md:border-none"
  >
    <div class="flex flex-col">
      <label for="" class="text-xs md:text-sm">{{
        title.slice(0, 24)
      }}</label>
      <label for="" class="text-xs md:text-sm">{{
        title.slice(25, 50)
      }}</label>
    </div>
    <div
      class="flex items-center gap-2 font-semibold text-xs xs:font-medium md:text-sm"
    >
      <input
        type="radio"
        [name]="title"
        value="0"
        [checked]="selected == 0"
        (click)="sendValue($event, title, $event.target['value'])"
      />
      Yes
      <input
        type="radio"
        [name]="title"
        value="99"
        [checked]="selected == 99"
        (click)="sendValue($event, title, $event.target['value'])"
      />
      No
    </div>
  </div>
</div>
