import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { DynamicSelectComponent } from './components/dynamic-select/dynamic-select.component';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { WebPlanComponent } from './components/web-plan/web-plan.component';
import { WebTopupsComponent } from './components/web-topups/web-topups.component';
import { SummaryVehicleDetailsComponent } from './components/summary-vehicle-details/summary-vehicle-details.component';
import { HeaderV2Component } from './components/header-v2/header-v2.component';
import { DynamicSelectV2Component } from './components/dynamic-select-v2/dynamic-select-v2.component';
import { FooterV2Component } from './components/footer-v2/footer-v2.component';
import { LoaderV2Component } from '../spinner_css/loader-v2/loader-v2.component';

const Component = [
  HeaderComponent,
  FooterComponent,
  DrawerComponent,
  DynamicSelectComponent,
  SidebarComponent,
  WebPlanComponent,
  WebTopupsComponent,
  SummaryVehicleDetailsComponent,
  HeaderV2Component,
  DynamicSelectV2Component,
  FooterV2Component,
  LoaderV2Component
]

@NgModule({
  declarations: [...Component],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[...Component]
})
export class SharedModule { }
