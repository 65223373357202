import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-summary-vehicle-details',
  templateUrl: './summary-vehicle-details.component.html',
  styleUrls: ['./summary-vehicle-details.component.scss']
})
export class SummaryVehicleDetailsComponent {

  @Input() quoteDetailPageDetails

}
