<div class="pt-5 px-8 pb-8 flex flex-col justify-between h-full  shadow-home rounded-lg  " [ngClass]="plan.isSelected ? 'border border-[#F57539]': ''">
    <div class="flex gap-8 flex-col ">
        <div [ngClass]="plan.isSelected ? 'bg-[#F57539] text-white' : 'bg-[#F6F6F6]'" class="flex flex-col gap-8 justify-center items-center p-5 rounded-lg ">
            <h1 class="text-xl font-semibold">{{plan.productName}}</h1>
            <p class="text-base font-semibold">AED {{plan.payablePremium.toFixed(2) | number}}/<span class="font-medium">year</span> </p>
        </div>
        <ul class="list-none flex flex-col gap-5">
            <li class="flex gap-3" *ngFor="let mandatory of plan.listOfBenefits.mandatory"><img class="h-6" src="assets/svg/black-tick.svg" alt="">{{mandatory.coverDesc}}</li>
        </ul>
    </div>
    <div class="flex items-center justify-center mt-12">
        <button [ngClass]="plan.isSelected ? 'text-white bg-[#F57539]' : 'text-[#F57539]'" class=" border border-[#F57539] flex items-center py-2 px-4 rounded-full gap-3">
            Choose the  plan 
            <img *ngIf="!plan.isSelected" src="assets/svg/orange-arrow.svg" alt="">
            <img *ngIf="plan.isSelected" src="assets/svg/white-arrow.svg" alt="">
        </button>
    </div>
</div>
