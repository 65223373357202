import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/create-quote/home/home.component';
import { ErrorComponent } from './modules/error/error/error.component';
import { ExpiryComponent } from './modules/error/expiry/expiry.component';
import {PaymentSuccessComponent} from './modules/payment-success/payment-success.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { KycComponent } from './modules/auth/kyc/kyc.component';
import { EmailUpdateComponent } from './modules/auth/email-update/email-update.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { PaymentSuccessV2Component } from './modules/payment-success-v2/payment-success-v2.component';
import { OtpComponent } from './modules/auth/otp/otp.component';


const routes: Routes = [

    // {
    // path:'verify-order',
    // component:PaymentSuccessComponent
    // },
    {
    path:'verify-order',
    component:PaymentSuccessV2Component
    },
    {
    path:'journey-done',
    component:PaymentSuccessV2Component
    },
    {path:'login',component:LoginComponent},
    {path:'otp',component:OtpComponent},
    {path:'forgot-password',component:ForgotPasswordComponent},
    {path:'reset-password',component:ResetPasswordComponent},
    {path:'kyc',component:KycComponent},
    {path:'email-update',component:EmailUpdateComponent},
    {path:'::customerUuid/app-expiry',component:ExpiryComponent},
    {path: '', loadChildren: ()=>import('./modules/create-quote/create-quote.module').then((m)=>m.CreateQuoteModule)},
    {path:'**',component:ErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
