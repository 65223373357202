import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api-service/api.service';
import { API_ENDPOINTS } from '../../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(
    private apiService: ApiService,
  ) { }

  async verifyOtp(body){
    try {
      const response = await this.apiService.postApiCall(`${API_ENDPOINTS.verifyOtp}`,body)
      return response.response
    } catch (exception) {
      throw exception
    }
  }
}
