import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api-service/api.service';
import { API_ENDPOINTS } from '../../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class CreateQuoteService {


  constructor(private  apiService:ApiService) { }

  async creteQuote(params) {
    try {
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS.createQuote}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async createQuoteResponse(){
    try {
      const result = await this.apiService.getApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.product}`)
      return result.response
    } catch (error) {
      throw error
    }
   }

   async createCustomerVehicleDetailsForQuote(){
    try {
      const result = await this.apiService.getApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.customerVehicleDetailForCreateQuote}`)
      return result
    } catch (error) {
      throw error
    }
  }
   async selectedProduct(product:any){
    try {
      const result = await this.apiService.postApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.selectedProduct}`,product)
      return result
    } catch (error) {
      throw error
    }
   }

   async updateWorkFlowStatus(param:any){
    try {
      const result = await this.apiService.postApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.updateWorkFLow}`,param)
      return result
    } catch (error) {
      throw error
    }
   }
   async errorRedirectQuote(param:any){
    try {
      const result = await this.apiService.postApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.updatefailedQuoteStatusForbackButton}`,param)
      return result
    } catch (error) {
      throw error
    }
   }
   async selectedPlantoProducts(product:any){
    try {
      const result = await this.apiService.postApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.lisOfPolicy}`,product)
      return result
    } catch (error) {
      throw error
    }
   }
   
}
