import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AxiosInstance } from "axios";
import { ToastrService } from "ngx-toastr";
import { flowBasedRouting } from "src/app/shared/mock-data";
// import { QuoteSummaryService } from "src/app/shared/services/quote-summary/quote-summary.service";
import { SessionService } from "src/app/shared/services/session-service/session.service";
import { CUSTOMERUUVID } from "src/app/shared/session-variables";
// import { TOKEN } from "src/assets/session-variables";
@Injectable({
  providedIn: "root",
})
export class BaseInterceptor {
  customerUuvid:any;
  flowdata = flowBasedRouting;
  constructor(private router: Router, private sessionService: SessionService, private toasterService: ToastrService) {}

  interceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use(
      (request) => {
        // const insurerURL = this.sessionService?.get("insurerName");
        // const reinsurerName = this.sessionService?.get("reinsurerName");
        this.customerUuvid  = this.sessionService.get(CUSTOMERUUVID);
        const token = this.sessionService?.get('token');
        // const clientCode = this.sessionService?.get("clientCode");
        request.headers["Authorization"] = `Bearer ${token}`;
        // request.headers["Authorization"] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMTU2NTY3NyIsImlhdCI6MTcxNjUyOTE5NiwiZXhwIjoxNzE2NTcyMzk2fQ.Yet6ADa9EIx7PYLr15RVvV_XK7oUzUoMxe5ZqXN2wXw`;
        // request.headers["client_code"] = `${clientCode}`;
        // request.headers["insurerURL"] = `${insurerURL}`;
        // request.headers["reinsurerName"] = `${reinsurerName}`;
        return request;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
      //  let insurerName = this.sessionService.get('insurerName')
      //  let reinsurerShortForm =  this.sessionService.get('reinsurerShortForm');
        if (error.response.status === 401) {
          this.router.navigateByUrl(`/login`)
          // this.toasterService.error(error?.response?.data?.message || "Unauthorized");
          // this.router.navigateByUrl(`/`);
        } else if (error.response?.status === 403) {
          // this.toasterService.error(error?.response?.data?.message || "Forbidden");
        } else if (error.response?.status === 404) {
      // await this.getCustomerLastWorkflow();

          // this.toasterService.error(error?.response?.data?.message || "Data not found");
        }
         else if (error.response?.status === 400) {
          // await this.getCustomerLastWorkflow();
          // this.toasterService.error(error?.response?.data?.message || "Something went wrong");
        }
        return Promise.reject(error);
      }
    );
  };

  // async getCustomerLastWorkflow() {
  //   try {
  //     let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
  //     this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
  //   } catch (exception) {
  //     // this.toaster.error(exception.message)
  //   }
  // }
}


