import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycService } from 'src/app/shared/services/kyc/kyc.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUUVID } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup
  customerUuid:string
  version:string
  currentPasswordVisibility: boolean = false
  newPasswordVisibility: boolean = false
  confirmPasswordVisibility: boolean = false
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private kycService: KycService,
    private router: Router,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private location: Location
  ) {

  }

  ngOnInit(): void {
    this.customerUuid = this.sessionService.get(CUSTOMERUUVID)
    this.version = this.sessionService.get('version')
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required,this.passwordMisMatchValidator()]],
    })
  }
  get formControl() {
    return this.resetPasswordForm.controls
  }

   passwordMisMatchValidator():ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null =>{
      const value = control.value
      if(!value){
        return null
      }
      if(value === this.formControl['newPassword'].value){
        return null
      }
      return {passwordMismatch:true}
    }
  }

  toggleCurrentPasswordVisibility(){
    this.currentPasswordVisibility = !this.currentPasswordVisibility
  }

  toggleNewPasswordVisibility(){
    this.newPasswordVisibility = !this.newPasswordVisibility
  }

  toggleConfirmPasswordVisibility(){
    this.confirmPasswordVisibility = !this.confirmPasswordVisibility
  }

  goBack(){
    this.location.back()
  }

  async resetPassword() {
    try {
      this.spinner.show()
      const response = await this.kycService.resetPassword({
        customerUuid:this.customerUuid,
        version:this.version,
        oldPassword: this.resetPasswordForm.value['password'],
        newPassword: this.resetPasswordForm.value['newPassword']
      })
      if(response){
        this.toaster.success("Password updated successfully")
        this.router.navigate([`/login`])
      }
    } catch (error) {
      // this.toaster.error('Something went wrong while sending credentials')
    }
    finally{
      this.spinner.hide()
    }
  }
}
