<app-loader-v2 *ngIf="showLoader"></app-loader-v2>
<div *ngIf="!showLoader" class="flex flex-col items-center justify-between h-screen font-aller">
   <div class="w-full  flex flex-col items-center justify-between h-full">
    <div class="flex justify-center items-center w-full">
        <app-header-v2 class="w-full flex justify-center items-center" [loading]="4" ></app-header-v2>
    </div>
        <div class=" w-full flex items-center justify-center flex-col p-4 text-[#333333] max-w-[768px]">
            <div class=" text-center mb-4 md:text-[1.625rem]">Congratulations! You just purchased <br> your Motor Insurance from Liva. </div>
            <div class=" text-center mb-2 md:mb-4 md:text-[1.625rem]">Please submit your documents for <br>the policy issuance.</div>
                <button class="w-full  bg-[#FF6B00] text-center rounded-full h-12 text-white   font-bold text-xl md:max-w-[18rem]" (click)="redirectToUploadDocument()">Submit Documents</button>
        </div>
        <div class="w-full">
            <app-footer-v2 class="w-full"></app-footer-v2>
        </div>
    </div>
</div>