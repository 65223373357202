<div class="topup-container mb-[2dvh]">
    <h3 class="mb-4">Top-ups</h3>
    <div class="flex gap-4 items-center">
        <ul class="topup-parent flex  gap-3 " >
            <li class="group" [ngClass]="list.isSelected ? 'topup-individual-clicked bg-[#FF6B00]': 'topup-individual'" class="flex flex-col rounded-[20px] border border-[#FF6B00] justify-between items-center min-h-44 px-4 pb-4"  *ngFor="let list of topUpList" (click)="clickToSelectTopUpList(list.id)">
                <p></p>
                <p class="text-sm font-normal  text-center mb-[0.425dvh]" [ngClass]="list.isSelected ? 'title-clicked text-white' : 'title'">{{list.coverDesc}}</p>
                <p class="text-lg font-medium group-[.topup-individual-clicked]:font-semibold text-center" [ngClass]="list.isSelected ? 'price-clicked text-white' : 'price'"><span *ngIf="!list.premium">INCLUDED</span> <span *ngIf="list.premium">AED {{list.premium}}</span></p>
            </li>    
        </ul>
        <div class="total-container flex-col flex items-center justify-between  mb-[1.7dvh] gap-4 shadow-selected-plan py-11 px-6 rounded-2xl ">
            <div class="text-center flex  items-center">
                <p class="text-xl font-normal text-[#FF6B00]">AED</p>
                <p class="font-semibold text-4xl text-[#FF6B00] xs:text-lg">{{totalPrice.toFixed(2)}}</p>
            </div>
            <div class="w-full text-center">
                <button class="font-semibold border w-full  border-[#E37E2B] text-[#E37E2B] text-2xl px-9 rounded-3xl" (click)="createQuote()">Yalla!</button>
            </div>
        </div> 
    </div>
</div>
<div>
    <app-footer></app-footer>
</div>