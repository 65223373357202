

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import {CURRENTWORKFLOW, PREVIOUSWORKFLOW, QUOTEENQUIRYID, TOKEN} from 'src/app/shared/interface/master-interface';
import {flowBasedRouting} from 'src/app/shared/mock-data';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import {PaymentService} from 'src/app/shared/services/payment/payment.service';
import {QuoteSummaryService} from 'src/app/shared/services/quote-summary/quote-summary.service';
import {SessionService} from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUUVID } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-payment-success-v2',
  templateUrl: './payment-success-v2.component.html',
  styleUrls: ['./payment-success-v2.component.scss']
})

export class PaymentSuccessV2Component implements OnInit {

  currentPath:string
  quoteEnquiryId: number;
  showLoader: boolean = true
  previousWorkFlow: string;
  currentWorkflow: string;
  version: number;
  token: string;
  public premium:number;
  flowdata = flowBasedRouting;
  customerVehicleDetails:any

  workflowStatus = [
    {
      id: 1,
      path: 'confirm-details',
      text: 'Your Details'
    },
    {
      id: 2,
      path: 'quote-summary',
      text: 'Your Quote'
    },
    {
      id: 3,
      path: 'verify-order',
      text: 'Payment'
    },
    {
      id: 4,
      path: 'document-upload',
      text: 'Documents'
    }
  ];
  customerUuvid:any;

  constructor(
    private router : Router,
    private sessionStorage: SessionService,
    private paymentService:PaymentService,
    private activeRoute:ActivatedRoute,
    private quoteSumaryService: QuoteSummaryService,
    private spinner: NgxSpinnerService,
    private navbarService: NavbarService


  ){

  }

  async ngOnInit(): Promise<void> {
    try {
      this.spinner.show()
      
      this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID);
        this.token = this.sessionStorage.get(TOKEN);
        this.customerUuvid  = this.sessionStorage.get(CUSTOMERUUVID);
        this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
        this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
        this.currentPath = this.router.url.split('/')[1].split('?')[0]
        this.customerVehicleDetails = await this.navbarService.getCustomerVehicleDetailsForQuotes()
      await this.verifyOrder();

    } catch (error) {

    }
    finally{
      this.spinner.hide()
    }

  }

  async verifyOrder()
  {
   try {
     const orderId=this.activeRoute.snapshot.queryParamMap.get("orderId");
     let params = {
      orderId:orderId,
      quoteEnquiryId:this.quoteEnquiryId,
      currentWorkFlow:this.currentWorkflow
     }
     let response = await this.paymentService.paymentStatus(params);
     console.log("REsponse",response.statusCode)
     if (response?.statusCode == 200)
      {
        this.premium=response["response"][0]["premium"]
        this.showLoader = false
      }
      else
      {
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
      console.log("getFlows",getFLows[0])
      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
      }

   } catch (error) {
    console.log("Error",error)
   }
   finally{
    // this.showLoader = false
  }
  }

  async redirectToUploadDocument()
  {
    try {
      this.spinner.show()
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
      console.log("getFlows",getFLows)
      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
    } catch (error) {

    }
    finally{
      this.spinner.hide()
    }
  }
}
