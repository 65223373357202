<div class="h-full bg-[rgba(163,163,163,.1)] pt-7 pl-7 w-full rounded-3xl">
    <div class="text-center" *ngFor="let workflow of workflowStatus">
        <div class="flex items-center gap-4">
            <img *ngIf="pathId < workflow.id" [src]="workflow.img" alt="" class="h-[3.75rem]">
            <img *ngIf="pathId >= workflow.id"  [src]="workflow.imgCurrentUrl" class="h-[3.75rem]" alt="">
            <p class="font-semibold text-xl">{{workflow.text}}</p>
        </div>
        <div class="ml-8">
            <img [ngClass]="workflow.id ==4 ? 'hidden':''" class="  " src="assets/svg/joining-line.png" alt="">
        </div>
    </div>
</div>
