import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

 @Input() pathId:number = 1
  constructor(
    private router:Router
  ){

  }
  currentUrl:string

  workflowStatus = [
    {
      id: 1,
      path: 'quote-generated',
      text: 'Your quote',
      img:'assets/svg/your-quote-bw.svg',
      imgCurrentUrl:'assets/svg/your-quote-orange.svg',
    },
    {
      id: 2,
      path: 'quote-summary',
      text: 'Your details',
      img:'assets/svg/your-details-bw.svg',
      imgCurrentUrl:'assets/svg/your-details-orange.svg',
      type:''
    },
    {
      id: 3,
      path: 'payment-start',
      text: 'Payment',
      img:'assets/svg/payment-bw.svg',
      imgCurrentUrl:'assets/svg/payment-orange.svg'
    },
    {
      id: 4,
      path: 'document-upload',
      text: 'Policy',
      img:'assets/svg/policy-bw.svg',
      imgCurrentUrl:'assets/svg/policy-orange.svg'
    }
  ]

  ngOnInit(): void {
    this.currentUrl = window.location.href.split("/")[4]
  }


}
