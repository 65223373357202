import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api-service/api.service';
import { API_ENDPOINTS } from 'src/app/shared/api-endpoints';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  tokenFromUI = "93389a26699r89k3";
  encrypted;
  isCaptchaValid: boolean;

  constructor(private  apiService:ApiService) { }



  async signIn(params) {
    try {
      console.log("-params",params)
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS.signIn}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception;
    }
  }

  encryptUsingAES256(req) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      req, _key, {
        keySize: 32,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }

  async validateCaptcha(captchaCode,loginForm) {
    this.isCaptchaValid = false;
    if (loginForm.value.captchaText.toLowerCase() === captchaCode.toLowerCase()) {
      // Captcha matched, proceed with the login logic
      this.isCaptchaValid = true;
      // Add your login logic here
    } else {
      // Captcha did not match
      this.isCaptchaValid = false;
    }
    return this.isCaptchaValid;
  }

  async generateCaptcha(length: number){
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    console.log(result)
    return result;
  }
}
