import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CallCenterService } from './shared/services/call-center/call-center.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private router:Router){
  console.log("----------------Deployed 11-JULY-2024 02.50pm")
  }
  ngOnInit(): void {
      // let  workflow = 'quote_details';
      // this.router.navigateByUrl(`/${workflow}`)
  }
  title = 'salik-poc-ui';
}
