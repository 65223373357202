import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common-service/common-service.service';
import { OtpService } from 'src/app/shared/services/otp/otp.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit{
  customerName: string = "Rashid" 
  obscuredEmail: string
  otpString: any = 0
  config = {
    length : 6
  }

  constructor(
    private toaster: ToastrService,
    private otpService: OtpService,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private commonService: CommonService
  ){

  }

  ngOnInit(): void {
    this.obscureEmail("rashidkhan294@gmail.com")
  }

  // Setting the otp value inside a variable

  setOtp(e){
    this.otpString = e
  }

  //Function for Sending Otp For Verification

 async submitOtp(){
    if(this.otpString.length !== 6){
      this.toaster.warning("Please enter the complete OTP")
    }else{
      try {
        this.spinner.show()
        const body = {
          otp:this.otpString,
          customerUserId:this.sessionService.get('customerUserId')
        }
        const response = await this.otpService.verifyOtp(body)
        console.log(response)
        if(response){
          this.toaster.success("OTP verified successfully")  
          this.router.navigateByUrl(`/${response[0]['customerUuvid']}`)
          // this.commonService.getCustomerLastWorkflow(response[0]['customerUuvid'])
        }
      } catch (error) {
        console.log(error)
        if (error.response.data["statusCode"] == 400) {
          this.toaster.warning("Invalid OTp")
        }
      }
      finally{
        this.spinner.hide()
      }
    }
  }

  obscureEmail(email:string){
    const[username,domain] =  email.split('@')
    const startLength = 2
    const endLength = 1

    //If email is too short just return it as it is
    if(email.length <= startLength + endLength){
      this.obscuredEmail = email
    }

    //Creating the obscure email

    const visibleStart = username.substring(0, startLength)
    const visbleEnd = username.substring(username.length - endLength)
    const obscurePart = '*'.repeat(username.length - startLength - endLength)

    const obscuredName = `${visibleStart}${obscurePart}${visbleEnd}`
    this.obscuredEmail = `${obscuredName}@${domain}`
  }

  // Function for resending otp
  resendOtp(){
  }
}
