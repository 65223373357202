import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycService } from 'src/app/shared/services/kyc/kyc.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.scss']
})
export class EmailUpdateComponent {
  emailUpdateForm: FormGroup
  customerUuid:string
  version:string
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private kycService: KycService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params)=>{
      this.customerUuid = params.get('customerUuid')
    })
    this.version = this.sessionService.get('version')
    this.emailUpdateForm = this.fb.group({
      email: ['', [Validators.required, Validators.email,]]
    })
  }
  get formControl() {
    return this.emailUpdateForm.controls
  }

  async updateEmail() {
    try {
      this.spinner.show()
      const response = await this.kycService.updateEmail({
        customerUuid:this.customerUuid,
        version:1,
        email: this.emailUpdateForm.value['email']
      })
      if(response){
        this.toaster.success("Email updated successfully")
        this.router.navigate([`/login`])
      }
    } catch (error) {
      // this.toaster.error('Something went wrong while updating email')
    }
    finally{
      this.spinner.hide()
    }
  }

}
