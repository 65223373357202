
<app-loader-v2 *ngIf="showLoader"></app-loader-v2>
<div class="h-[100dvh] flex flex-col justify-between items-center p-4" *ngIf="!showLoader">
    <div class="w-full mb-4">
        <span class="md:hidden">
            <app-header></app-header>
        </span>
        <div class="hidden md:block md:pl-[3.75rem] md:py-6 items-center border-b-2">
            <app-header [customerVehicleDetails]="customerVehicleDetails"></app-header>
        </div>
        <div class="md:hidden mt-4 flex w-full gap-1 items-center">
            <div class="w-[20%] flex flex-col flex-grow" *ngFor="let workFlow of workflowStatus">
                <p [ngClass]="workFlow.path !== currentPath ? 'invisible' : '' " class="font-semibold text-center mb-2 text-sm xs:text-xs ">{{  workFlow.text }}</p>
                <div [ngClass]="workFlow.path === currentPath ? 'bg-[#FF6B00]' : 'bg-[#D9D9D9]' " class="w-full  rounded h-1"></div>
            </div>
        </div>
    </div>
    <div class="md:flex w-full gap-8 md:h-[calc(100dvh-10.5rem)]">
        <!-- SIDE BAR -->
        <div class="w-1/4 max-w-80 h-full hidden md:block">
            <app-sidebar [pathId]="3"></app-sidebar>
        </div>
        <div class="md:w-full relative ">
            <div class=" md:border-none flex-1 flex justify-center items-center border w-full border-[#FF6B00] mb-[1.7dvh] rounded-3xl">
                <div class=" ">
                    <div class="flex flex-col gap-6 ">
                        <img src="/assets/svg/celebration1.svg" alt="Celebration" class="h-48 object-contain">
                        <div class="flex flex-col gap-[1.7dvh]">
                            <h3 class="font-normal text-[32px] text-center">Mabrook!</h3>
                            <p class="text-center text-[15px]">You just bought your car insurance</p>
                        </div>
                        <div class="flex justify-center items-center gap-1">
                            <p class="text-[13px]">AED</p>
                            <h3 class="text-[#FF6B00] font-semibold text-3xl">{{premium | number}}</h3>
                        </div>
                    </div>
                    </div>
            </div>
                <div class="w-full">
                <p class="text-center py-8">Would you like to submit the required documents for policy issuance?</p>
                <div class="mt-[1.7dvh] mb-[.9dvh] flex gap-[0.9dvh] md:justify-center md:hover:opacity-70 pb-20">
                    <!-- <button class="w-1/4 border border-black rounded-full">Later</button> -->
                    <button class="md:w-[50%] md:rounded-md w-full bg-[#FF6B00] text-center rounded-full h-[6.8dvh] text-white  font-semibold text-xl" (click)="redirectToUploadDocument()">Submit Documents</button>
                </div>
                <div class="absolute bottom-0 right-0 left-0">
                <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>

</div>
