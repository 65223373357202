<div class="flex flex-col justify-between items-center h-screen">
    <div class="w-full">
        <!-- <app-header [hideProfileIcon]="true" [hideShareIcon]="true"></app-header> -->
        <app-header-v2  [pageName]="'kyc'"></app-header-v2>

    </div>
    <div class="flex-1 flex-col flex justify-center items-center w-full">
        <div class="flex justify-between w-full max-w-96">
            <button type="button" (click)="backToLogin()"> 
                <img  src="/assets/svg/back.svg" alt="back">
            </button>
            <h1 class="text-3xl font-semibold my-8 ">KYC Form</h1>
            <div></div>
        </div>
        <div class="w-full h-1 bg-[#FF6B00] max-w-96"></div>
        <form [formGroup]="kycForm" class="border  w-full p-4 bg-gray-100 max-w-96" (ngSubmit)="checkKyc()">
            <div class="flex flex-col gap-4 ">
                <p class="text-center text-sm text-black">Enter your KYC details to update email</p>
                <div class="flex flex-col gap-2" >
                    <label class="font-semibold" for="name">Name</label>
                    <input class="h-8" name="name"  type="text" formControlName="name">
                    <div *ngIf="formControl['name'].touched && formControl['name'].invalid">
                        <div *ngIf="formControl['name'].errors['required']" class="text-[#FF6B00] text-sm">Name is required</div>
                        <div *ngIf="formControl['name'].errors['minlength']" class="text-[#FF6B00] text-sm">Name mustn't be less than 8 character</div>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="font-semibold" for="password" >DOB</label>
                    <input class="h-8" name="dob"  type="date" formControlName="dob" id="">
                    <div *ngIf="formControl['dob'].touched && formControl['dob'].invalid">
                        <div *ngIf="formControl['dob'].errors['required']" class="text-[#FF6B00] text-sm">dob is required</div>
                        <div *ngIf="formControl['dob'].errors['minlength']" class="text-[#FF6B00] text-sm">dob Must be 8 character long</div>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="font-semibold" for="password" >Emirates Id</label>
                    <input class="h-8" name="emiratesId"  type="text" formControlName="emiratesId" id="">
                    <div *ngIf="formControl['emiratesId'].touched && formControl['emiratesId'].invalid">
                        <div *ngIf="formControl['emiratesId'].errors['required']" class="text-[#FF6B00] text-sm">EmiratesId is required</div>
                        <div *ngIf="formControl['emiratesId'].errors['minlength']" class="text-[#FF6B00] text-sm">EmiratesId Must be 8 character long</div>
                    </div>
                </div>
            </div>
            <div class="text-center my-4">
                <button class="bg-[#FF6B00] text-center rounded-full  text-white p-2 w-full  font-semibold text-xl" type="submit">Submit</button>
            </div>
        </form>
    </div>
    <div class="w-full">
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>
