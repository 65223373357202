<div class="flex flex-col justify-between items-center h-screen ">
    <div class="w-full">
        <!-- <app-header [hideProfileIcon]="true" [hideShareIcon]="true"></app-header> -->
        <app-header-v2  [pageName]="'update-email'"></app-header-v2>
    </div>
    <div class="flex-1 flex-col flex justify-center items-center w-full">
        <h1 class="text-3xl font-semibold my-8 ">Update Email</h1>
        <div class="w-full h-1 bg-[#FF6B00] max-w-96"></div>
        <form [formGroup]="emailUpdateForm" class="border  w-full p-4 bg-gray-100 max-w-96" (ngSubmit)="updateEmail()">
            <div class="flex flex-col gap-4 ">
                <p class="text-center text-black text-sm">
                    Please provide a valid email address to receive your LogIn credentials.</p>
                <div class="flex flex-col gap-2" >
                    <label class="font-semibold" for="email">Email</label>
                    <input class="h-8" name="email"  type="email" formControlName="email">
                    <div *ngIf="formControl['email'].touched && formControl['email'].invalid">
                        <div *ngIf="formControl['email'].errors['required']" class="text-[#FF6B00] text-sm">Email is required</div>
                        <div *ngIf="formControl['email'].errors['email']" class="text-[#FF6B00] text-sm">Enter Valid Email</div>
                    </div>
                </div>
            </div>
            <div class="text-center my-4">
                <button class="bg-[#FF6B00] text-center rounded-full  text-white p-2 w-full  font-semibold text-xl" [disabled]="emailUpdateForm.invalid" type="submit">Update</button>
            </div>
        </form>
    </div>
    <div class="w-full">
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>
